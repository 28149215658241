export function useFile() {

    const downloadFileContent = ( fileContent, fileName, contentType = 'text/csv;charset=utf-8;') =>{
        const byteCharacters = atob(fileContent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const fileBlob = new Blob([byteArray], { type: contentType });
    
        // Create a link and trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(fileBlob);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    
        // Clean up and remove the link
        document.body.removeChild(link);
    }
    
    return {
        downloadFileContent
     }

}