import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useBackend } from '@/composables/backend'

const STORE_NAME = 'auth'
const baseUri = process.env.VUE_APP_API_URL

export const useAuthStore = defineStore(STORE_NAME, () => {
    
    
    const csrfToken = ref(null)           // We'll store the CSRF token here.     // The user's profile data

    const lastModule = ref(null);

    function saveLastModule(moduleName) {
        lastModule.value = moduleName;
    }

    function getLastModule() {
        return lastModule.value;
    }

    const { item: currentUser, getItem } = useBackend('user')
    const { item: currentTenant, getItem: getTenant } = useBackend('tenant')

    const payRollIntegrationName = computed( () => {
        return currentTenant?.value?.integrations?.find(a => a.type === "PayrollSystem")?.name || "Payroll Provider"
    })

    const posIntegrationName = computed( () => {
        return currentTenant?.value?.integrations?.find(a => a.type === "PosSystem")?.name || "Point of Sale System"
    })

    const accountingIntegrationName = computed( () => {
        return currentTenant?.value?.integrations?.find(a => a.type === "AccountingSystem")?.name || "Accounting Software"
    })

    const permissions = computed(() => currentUser.value?.permissions || [])

    const hasPermission = (permission) => {
        return permissions.value.includes(permission)
    }
    
    const isLoggedIn = computed(() => {
        return (csrfToken.value !== null)
    })

    const isImpersonating = computed( () => {
        return currentUser.value?.impersonatedTenantId !== null 
    })

    async function login(username, password){
        const res = await fetch(`${baseUri}/user/login`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })

        if (!res.ok) {
            if (res.status === 401) {
                throw new Error('Incorrect username or password.')
            } else {
                throw new Error(`Login failed with status: ${res.status}`)
            }
        }

        const data = await res.json()
        
        csrfToken.value = data.csrfToken || null

        await getItem('current')
        await getTenant('current')

        return data
    }

    async function logout(){
        try {
            await fetch(`${baseUri}/user/logout`, {
              method: 'POST',
              credentials: 'include'
            })
        } catch (error) {
        console.error('Logout request failed, but clearing local state anyway.', error)
        }

        // Clear local store
        currentUser.value = null
        currentTenant.value = null
        csrfToken.value = null
        isImpersonating.value = false
    }

    async function impersonate(tenantId) {
        try {
            const res = await fetch(`${baseUri}/tenant/${tenantId}/impersonate`, {
                method: 'POST',
                credentials: 'include'
            })

            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`);
            }

            const data = await res.json();

            // Update auth.value with new accessToken and expiration
            csrfToken.value = data.csrfToken || csrfToken.value

            await getItem('current');
            await getTenant('current')

            return data

        } catch (error) {
            console.error("Failed to impersonate:", error);
            throw error;
        }
    }

    async function revertImpersonation() {
        try {
            const res = await fetch(`${baseUri}/tenant/revertImpersonation`, {
                method: 'POST',
                credentials: 'include'
            })
            if (!res.ok) {
                throw new Error(`HTTP error! status: ${res.status}`)
            }

            isImpersonating.value = false

            await getItem('current')
            await getTenant('current')

        } catch (error) {
            console.error("Failed to revert impersonation:", error);
            throw error;
        }
    }

    return { 
        // state
        csrfToken,
        currentUser,
        currentTenant,

        // getters/computed
        payRollIntegrationName,
        posIntegrationName,
        accountingIntegrationName,
        isLoggedIn,
        isImpersonating,
        permissions,
        hasPermission,

        // actions
        login,
        logout,
        impersonate,
        revertImpersonation,

        // optional module tracking
        lastModule,
        saveLastModule,
        getLastModule
    }
}, { persist: true })