import { ref, toValue, toRaw } from 'vue'
import { useAuthStore } from '@/stores'
import { useRouter } from 'vue-router'

const baseUri = process.env.VUE_APP_API_URL

export function useBackend(endpoint, path) {
    const item = ref(null)
    const items = ref([])
    const error = ref(null)
    const totalItems = ref(0)
    const loading = ref(false)

    const router = useRouter()

    const getItems = async (params = {}) => {
        error.value = null

        // Resolve refs to their raw values
        const resolvedParams = Object.keys(params).reduce((acc, key) => {
            acc[key] = toValue(params[key]);
            return acc;
        }, {});

        // Build an array of key-value pairs
        const pairs = Object.entries(resolvedParams).flatMap(([key, value]) =>
            Array.isArray(value) ? value.map(item => [key, item]) : [[key, value]]
        );

        // Construct query string from pairs
        const queryString = new URLSearchParams(pairs).toString();
        const urlWithParams = `${baseUri}/${endpoint}?${queryString}`;


        const response = await customFetch(urlWithParams, {
            method: 'GET',
        });
        
        if (response) {
            items.value = response.data || response
            totalItems.value = response.totalRecords || items.value.length
        }
    
            }

    const getItem = async(id, params = {}) => {
        item.value = null
        error.value = null

        // Resolve refs to their raw values
        const resolvedParams = Object.keys(params).reduce((acc, key) => {
            acc[key] = toValue(params[key]);
            return acc;
        }, {});
        
        // Construct query string from params object
        const queryString = new URLSearchParams(resolvedParams).toString();
        const urlWithParams = `${baseUri}/${endpoint}/${id}?${queryString}`;
        
        const response = await customFetch( urlWithParams, {
            method: 'GET',
        });
        
        if (response) {
            item.value = response
        }

    }

    const downloadItem = async(id, params = {}) => {
        error.value = null

        const resolvedParams = Object.keys(params).reduce((acc, key) => {
            acc[key] = toValue(params[key]);
            return acc;
        }, {});
        
        const queryString = new URLSearchParams(resolvedParams).toString();
        const urlWithParams = `${baseUri}/${endpoint}/${id}?${queryString}`;

        const response = await customFetch(urlWithParams, { method: 'GET', responseType: 'blob' });

        if (response) {
            const blob = new Blob([response], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            const contentDisposition = response.headers.get('content-disposition');
            let fileName = 'downloaded-file';
            
            if (contentDisposition) {
                const match = contentDisposition.match(/filename\*=UTF-8''([^;]+)|filename="([^"]+)"/);
                if (match) {
                    fileName = decodeURIComponent(match[1] || match[2]);
                }
            }

            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        }
    }

    const addItem = async (data) => {
        error.value = null

        const response = await customFetch( `${baseUri}/${endpoint}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' }
        } )
        
        if (response) {
            item.value = response
            if( path )
            {
                router.push( `${path}/${item.value.id}`)
            }
            
        }
    }

    const addItemWithFile = async (data, file) => {
        error.value = null;
        const formData = new FormData();
    
        // Append files correctly
        if (Array.isArray(file)) {
            file.forEach(f => {
                formData.append("files", f);  // 'files' is the key expected by the backend
            });
        } else if (file) {
            formData.append("file", file);
        }
    
        // Append other data correctly, especially arrays
        for (const key in data) {
            if (Array.isArray(data[key])) {
                data[key].forEach(value => {
                    formData.append(`${key}`, value);
                });
            } else {
                formData.append(key, data[key]);
            }
        }
    
        const response = await customFetch(`${baseUri}/${endpoint}`, {
            method: 'POST',
            body: formData,
        });
    
        if (response) {
            item.value = response;
            if (path) {
                router.push(`${path}/${item.value.id}`);
            }
        }
    }

    const updateItem = async (id) => {
        
        error.value = null

        const rawData = toRaw(item.value);

        const response = await customFetch( `${baseUri}/${endpoint}/${id}`, { 
            method: 'PUT',
            headers: { 
                'Content-Type': 'application/json',
            },
            body:JSON.stringify(rawData) 
        } )
        
        if (response) {
            item.value = response
            if( path )
            {
                router.push( `${path}/${item.value.id}`)
            }
        }
    }

    const deleteItem = async (id) => {
        error.value = null
        const response = await customFetch( `${baseUri}/${endpoint}/${id}`, { method: 'DELETE' } )
        
        if (response) {
            if( path )
            {
                router.push( `${path}/${item.value.id}`)
            }
        }

    }

    async function customFetch(url, options = {}, retry = true) {
        const authStore = useAuthStore()
        loading.value = true
    
        const config = {
          ...options,
          credentials: 'include', // Important: send/receive HttpOnly cookies
          headers: {
            ...(options.headers || {})
          }
        }
    
        // If NOT a GET request, include the CSRF token in a header
        if (config.method && config.method.toUpperCase() !== 'GET') {
          const token = authStore.csrfToken
          if (token) {
            config.headers['x-csrf-token'] = token
          }
        }
    
        try {

        console.log( "Configuration: ", config )

          const response = await fetch(url, config)
    
          if (!response.ok) {
            // 401 / 403 might happen if CSRF fails or session is invalid
            if (response.status === 401 && retry) {
              // Optionally, we could auto-logout or try to refresh
              await refreshToken()
              return await customFetch( url, options, false)
            } else if ( response.status === 401 ) {
                authStore.logout()
            }
    
            // Attempt to parse error message
            let errorData = {}
            try {
              errorData = await response.json()
            } catch (e) {
              errorData = { message: response.statusText || 'An error occurred' }
            }
    
            error.value = {
              status: response.status,
              message: errorData.message || 'An error occurred',
              errors: errorData.errors || null
            }
            return null
          } else {
            loading.value = false
    
            // Return early if 204 (No Content)
            if (response.status === 204) {
              return
            }
    
            if (options.responseType === 'blob') {
              const blob = await response.blob()
              // let the caller see the response headers
              blob.headers = response.headers
              return blob
            } else {
              return await response.json()
            }
          }
        } catch (err) {
          console.log(err)
          error.value = { status: null, message: 'An unknown error occurred' }
          return null
        } finally {
          loading.value = false
        }
      }

      async function refreshToken() {
        try {
            const res = await fetch(`${baseUri}/user/refresh`, {
                method: 'POST',
                credentials: 'include' // Ensure cookies are sent automatically
            });
            if (!res.ok) {
                throw new Error(`Refresh failed with status: ${res.status}`);
            }
            const data = await res.json();
            // Optionally update your CSRF token or other relevant data
            return data;
        } catch (error) {
            console.error("Failed to refresh token:", error);
            // Optionally, log out the user or take other actions
            return null;
        }
    }

    return {
        item,
        items,
        totalItems,
        error,
        downloadItem,
        getItems,
        getItem,
        addItem,
        addItemWithFile,
        updateItem,
        deleteItem,
        loading
    }
}