import moment from 'moment'

export function useUtil() {
    const calcuateHours = (startDate, endDate) => {
        const diff = ( endDate.getTime() - startDate.getTime() ) / 1000
        return Math.abs( diff / ( 60 * 60 ) )
    }

    const getDatesArray = (startDate, endDate) => {
        const dates = []
        const start = moment(startDate)
        const end = moment(endDate)
        while ( end.diff(start,'days') >= 0 ) {
            dates.push( start.format('M/D/yyyy') )
            start.add( 1, 'day' )
        }
        return dates
    }

    const getDatesInLastQuarterToDate = () => {
        const end = moment()
        const start = moment().add(-1, 'quarter').startOf('quarter')
        return getDatesArray( start, end )
    }

    const parseJwt = (token) => {
        return JSON.parse(window.atob(token.split('.')[1]));
    }

    const formatDate = (dateString, includeTime = true, utcTime = true, includeDayOfWeek = false) => {
        if (!dateString) return 'N/A';
    
        // Remove extra milliseconds if present (e.g., ".0000000")
        dateString = dateString.replace(/\.\d+$/, '');
    
        // Split the date and time parts
        const [datePart, timePart = '00:00:00'] = dateString.split('T');
    
        // Split date into components
        const [year, month, day] = datePart.split('-').map(Number);
    
        // Split time into components
        const [hour, minute, second] = timePart.split(':').map(Number);
    
        let date;
    
        if (utcTime) {
            // Create a Date object in UTC
            date = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
        } else {
            // Create a Date object in local time
            date = new Date(year, month - 1, day, hour, minute, second);
        }
    
        if (isNaN(date)) return 'Invalid Date';
    
        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        };
    
        if (includeTime) {
            options.hour = 'numeric';
            options.minute = 'numeric';
            options.second = 'numeric';
            options.timeZoneName = 'short';
        }

        if (includeDayOfWeek) {
            options.weekday = 'short'; // Adds abbreviated weekday (e.g., "Mon")
        }
    
        return new Intl.DateTimeFormat(undefined, options).format(date);
    };

    const formatMetricValue = (value, metricName) => {
               
        const currencyMetrics = ['Gross profit', 'Sales', 'Total net sales', 'Payroll', 'Payroll cost', 'Adjusted payroll cost', 'Adjusted daily payroll cost'];
        const percentMetrics = ['Adjusted payroll percent of sales']
        const dateFields = ['Check Date']
        const stringFields = ['Store', 'Employee']

        if( value === 0 ){
            return "-"
        }
        
        if( stringFields.includes(metricName) )
        {
            return value
        }

        if( dateFields.includes(metricName))
        {
            return formatDate( value, false, false )
        }

        let style = 'decimal'

        if (currencyMetrics.includes(metricName)) {
            style = 'currency';
        }

        if( percentMetrics.includes(metricName) )
        {
            style="percent";
            value = value/100;
        }
    
        const defaultOptions = {
            style: style, // Can be 'decimal', 'currency', 'percent', etc.
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        };

        return new Intl.NumberFormat('en-US', defaultOptions).format(value);
    }

    const formatDatePickerDate = (date) => {
        const year = date.getFullYear();
        // Months are zero-based in JavaScript, so we add 1 and pad with zero if needed
        const month = String(date.getMonth() + 1).padStart(2, '0');
        // Pad day with zero if needed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const accountTypes = [
      { value: 0, title: 'Asset' },
      { value: 1, title: 'Liability' },
      { value: 2, title: 'Equity' },
      { value: 3, title: 'Revenue' },
      { value: 4, title: 'Expense' },
    ]

    const accountTypeMap = accountTypes.reduce((map, type) => {
        map[type.value] = type.title;
        return map;
    }, {});

    const types = [
        { value: 0, title: "Sale" },
        { value: 1, title: "Tax" },
        { value: 2, title: "Fee" },
        { value: 3, title: "Payment" },
        { value: 4, title: "Advance" },
        { value: 5, title: "Receivalbe" }
    ]

    const typeMap = types.reduce((map, type) => {
        map[type.value] = type.title;
        return map;
    }, {});

    const aggregationTypes = [
        { value:0 , title: 'Sum' },
        { value: 1, title: "Percentage" },
        { value: 2, title: "Average" },
        { value: 3, title: "Max" },
        { value: 4, title: "Min" },
    ]

    const aggregationTypeMap = aggregationTypes.reduce((map, type) => {
        map[type.value] = type.title;
        return map;
    }, {});

    function isEmpty(val) {
        // Check for null or undefined
        if (val == null) return true;
      
        // Check for empty array
        if (Array.isArray(val)) return val.length === 0;
      
        // Check for empty object
        if (typeof val === 'object') return Object.keys(val).length === 0;
      
        // Check for empty string (after trimming)
        if (typeof val === 'string') return val.trim().length === 0;
      
        // Otherwise, consider it non-empty
        return false;
      }

      function camelToKebab(str) {
        return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
      }

      function truncateText(text, maxLength) {
        if (!text) return '';
        return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
      }

    return { calcuateHours, getDatesArray, getDatesInLastQuarterToDate, parseJwt, formatDate, formatDatePickerDate, formatMetricValue, types, typeMap, aggregationTypes, aggregationTypeMap, accountTypes, accountTypeMap, isEmpty, camelToKebab, truncateText }
}